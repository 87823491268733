<template>
  <div class="applicant-rejection">
    <div class="applicant-rejection__close" @click="$emit('close')">
      <img src="@/assets/images/icons/x.svg" alt="close" />
    </div>

    <h3 class="applicant-rejection__title">Отказ в согласовании</h3>
    <p class="applicant-rejection__description">
      Укажите комментарий с причиной по которой отказываетесь согласовывать
      кандидата
    </p>
    <VTextarea
      placeholder="Комментарий для рекрутера*"
      class="applicant-rejection__rejection-reason"
      @input="validateInput"
      @blur="validateInput"
      v-model="form.rejection_reason"
      :error="!!formErrors.rejection_reason"
    />

    <div class="applicant-rejection__btns">
      <VButton
        label="Отказать в согласовании"
        bg="#DD4646"
        bgHover="#D10E0E"
        color="#fff"
        class="applicant-rejection__btn"
        :disabled="disabled"
        @click="reject"
      />
      <VButton
        label="Отменить"
        bg="#E4E7EE"
        bgHover="#BBC4CD"
        color="#1E3959"
        colorHover="#1E3959"
        class="applicant-rejection__btn"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";

const props = defineProps({
  data: Object,
});

const emit = defineEmits(["closeVacancy", "close", "reject"]);

const form = ref({
  rejection_reason: "",
});

const formErrors = ref({
  rejection_reason: "",
});

const validateInput = () => {
  formErrors.value.rejection_reason = "";
  if (form.value?.rejection_reason?.length < 1) {
    formErrors.value.rejection_reason = "Заполните обязательное поле";
  }

  return !!formErrors.value?.rejection_reason;
};

const reject = () => {
  console.log(
    "formErrors.value?.rejection_reason",
    formErrors.value?.rejection_reason,
    validateInput()
  );
  if (validateInput()) {
    return;
  }
  emit("reject", form.value);
};
</script>

<style scoped lang="scss">
.applicant-rejection {
  position: relative;
  padding: 40px 24px 24px 24px;
  width: 544px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__title {
    font-size: 20px;
    font-weight: 600;
  }

  &__description {
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 16px;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__rejection-reason {
    width: 100%;
    min-height: 130px;
    border: none;
    :deep(.v-textarea__placeholder) {
      color: $dark-blue;
    }
  }

  &__btns {
    display: flex;
    width: 100%;
    margin-top: 24px;
    gap: 12px;
  }

  &__btn {
    width: 100%;
  }
}
</style>
