<template>
  <div class="actions">
    <h3 class="actions__title" >{{ headerName ? headerName : 'Дейcтвия по отклику' }}</h3>

    <div class="actions__btns">
      <div v-for="action in additionalActions" class="actions__btn-child">
        <div
            :style="[
              iconSrc()[action?.icon]? 'text-align: left' : 'text-align: center',
              action.btn === 'GRAY' ? 'color: #BBC4CD; cursor: auto' : ''
              ]"
            :key="action.action"
            v-show="action.btn !== 'BLUE_FILLED'"
            @click="actionEmit({
            ...action,
            id: action?.action,
             }, action.btn === 'GRAY')
        "
            class="actions__btn"
            :class="{ actions__btn_red: action.btn === 'RED' }"
        >
          <img v-if="iconSrc()[action?.icon] && action.btn !== 'GRAY'" :src="iconSrc()[action?.icon]" alt="prev"/>
          <img v-if="iconSrc()[action?.icon] && action.btn === 'GRAY'" :src="checkDisabled" alt="prev"/>
          {{ action.title }}

        </div>

        <div
            v-show="action.btn === 'BLUE_FILLED'"
            :key="action.action"
            class="actions__footer"
        >
          <VButtonNew
              :label="action.title"
              :loading="loading"
              @click="
          emit('actionClick', {
            ...action,
            id: action?.action,
          })
        "
              clickEffect
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineComponent } from "vue";
import arrowImage from "@/assets/images/icons/prev-arrow.svg"
import crossImage from "@/assets/images/icons/no.svg"
import penImage from "@/assets/images/icons/pencil.svg"
import clockImage from "@/assets/images/icons/watch.svg"
import checkImage from "@/assets/images/icons/check-actions.svg"
import checkDisabled from "@/assets/images/icons/check-actions-disabled.svg"


const props = defineProps({
  actions: Array,
  canDelay: Boolean,
  headerName: String,
  loading: Boolean
});

const emit = defineEmits(["actionClick", "transferDecision"]);

const additionalActions = computed(() => {
  return props.actions
});

const iconSrc = () => {
    return {
        "ARROW" : arrowImage,
        "CROSS" : crossImage,
        "PEN"   : penImage,
        "CLOCK" : clockImage,
        "CHECK" : checkImage,
    }
}

const actionEmit = (arg, disabled) => {
  if(disabled) return
  emit("actionClick", arg)
}

</script>

<style scoped lang="scss">
.actions {
  width: 100%;
  padding: 24px 16px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 2px 2px 8px 0px #0000000a;

  &__title {
    margin-left: 0px;
    font-size: 14px;
    font-weight: 600;
  }

  &__transfer-decision {
    margin-top: 8px;
  }

  &__btns {
    margin-top: 16px;
  }

  &__btn {
    width: 100%;
    min-height: 32px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    color: $blue;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;

    img {
      margin-right: 3.5px;
    }

    &_red {
      color: $red;
    }

    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__btn-child {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }

  &__footer {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid $light-gray-1;
  }
}
</style>
