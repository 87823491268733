<template>
  <div class="applicant-rejection">
    <div class="applicant-rejection__close" @click="$emit('close')">
      <img src="@/assets/images/icons/x.svg" alt="close" />
    </div>

    <h3 class="applicant-rejection__title">Отказ соискателю</h3>
    <p class="applicant-rejection__description">
      Выберите причину для отказа. Выбранная причина сохраняется для последующей аналитики. Соискатель получит только сообщение указаное ниже.
    </p>

    <VSegmentedControl
      :items="whoDecline"
      v-model="form.whoDecline"
      class="applicant-rejection__control"
    />

    <VSelect
      class="applicant-rejection__select"
      placeholder="Выберите причину отказа*"
      :options="mapedReasons"
      v-model="form.rejection_reason_id"
      :error="error"
    />
    <p class="applicant-rejection__inp-error" v-if="error">Заполните обязательное поле</p>
    <div class="applicant-rejection__textarea">
      <p>Сообщение соискателю:</p>
      Благодарим за ваш отклик. Мы внимательно ознакомились с вашим резюме.
      К сожалению, на данном этапе мы не готовы предложить вам работу. Искренне
      желаем удачи в поисках работы!
    </div>

    <div class="applicant-rejection__btns">
      <VButton
        label="Отменить"
        bg="#E4E7EE"
        bgHover="#BBC4CD"
        color="#1E3959"
        colorHover="#1E3959"
        class="applicant-rejection__btn"
        @click="$emit('close')"
      />
      <VButton
        label="Отказать соискателю"
        bg="#DD4646"
        bgHover="#D10E0E"
        color="#fff"
        class="applicant-rejection__btn"
        :disabled="disabled"
        @click="refusal"
      />
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";

const props = defineProps({
  data: Object,
});

const emit = defineEmits(["closeVacancy", "close", "refusal"]);
const disabled = ref(false);
const reasonOptions = ref({});
const error = ref(false)

const form = ref({
  whoDecline: "we",
  rejection_reason_id: "",
});
const whoDecline = [
  {
    id: "we",
    label: "Отказали мы",
  },
  {
    id: "candidate",
    label: "Отказали нам",
  },
];
const whoDeclineSelect = ref("candidate");
const mapedReasons = computed(() => {
  return reasonOptions.value[form.value.whoDecline]?.map((item) => ({
    ...item,
    label: item?.reason,
  }));
});
const text = ref("");
const refusal = () => {
  if(!form.value.rejection_reason_id) {
    error.value = true
    return
  }
  disabled.value = true;
  emit("refusal", form.value);
};

onMounted(() => {
  reasonOptions.value = props?.data;
});

watch(() => form.value.rejection_reason_id, ()=>{
  error.value = false
})

watch(() => form.value.whoDecline, () => {
  error.value = false
  form.value.rejection_reason_id = ''
})

</script>

<style scoped lang="scss">
.applicant-rejection {
  position: relative;
  padding: 40px 24px 24px 24px;
  width: 477px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__title {
    font-size: 20px;
    font-weight: 600;
  }

  &__description {
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 14px;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__textarea {
    width: 100%;
    padding: 16px;
    margin-top: 16px;
    border-radius: 8px;
    font-size: 14px;
    background-color: $light;
    color: $gray;
    resize: none;
    text-align: left;

    p {
      margin-bottom: 4px;
      color: $dark-blue;
      font-size: 12px;
    }
  }

  &__control {
    margin-bottom: 16px;
    height: 40px;
    border-radius: 8px;
    :deep(.v-filtration__filter) {
      border-radius: 4px;
    }
  }

  &__select {
    width: 100%;
    text-align: left;
  }

  &__btns {
    display: flex;
    width: 100%;
    margin-top: 24px;
    gap: 12px;
  }

  &__btn {
    width: 100%;
  }

  &__inp-error {
    font-size: 14px;
    color: #DD4646;
    margin-right: auto;
  }
}
</style>
