<template>
  <div class="questionnaire">
    <div
      v-if="data.icons?.content_icon !== 'LOCK'"
      class="questionnaire__header"
    >
      <div class="questionnaire__header-left">
        <div class="questionnaire__header-status">
          <div
            v-if="
              data.icons?.description_icon === 'BLUE' ||
              data.icons?.description_icon === 'GRAY' ||
              data.icons?.description_icon === 'RED'
            "
            class="questionnaire__header-status-dot"
            :class="{
              'questionnaire__header-status-dot_blue':
                data.icons?.description_icon === 'BLUE',
              'questionnaire__header-status-dot_gray':
                data.icons?.description_icon === 'GRAY',
              'questionnaire__header-status-dot_red':
                data.icons?.description_icon === 'RED',
            }"
          ></div>

          <svg
            v-if="data.icons?.description_icon === 'GREEN_CIRCLE'"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="10.0002"
              cy="9.99822"
              r="7.43182"
              stroke="#26A659"
              stroke-width="1.5"
            />
            <path
              d="M13.5417 7.5L8.95833 12.0833L6.875 10"
              stroke="#26A659"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <svg
            v-if="data.icons?.description_icon === 'RED_X'"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0018 18.18C5.48308 18.18 1.81996 14.5169 1.81996 9.99822C1.81996 5.47953 5.48308 1.81641 10.0018 1.81641C14.5205 1.81641 18.1836 5.47953 18.1836 9.99822C18.1836 14.5169 14.5205 18.18 10.0018 18.18Z"
              stroke="#DD4646"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13 7L7 13"
              stroke="#DD4646"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7 7L13 13"
              stroke="#DD4646"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <svg
            v-if="data.icons?.description_icon === 'RED_CIRCLE'"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0018 18.18C5.48308 18.18 1.81996 14.5169 1.81996 9.99822C1.81996 5.47953 5.48308 1.81641 10.0018 1.81641C14.5205 1.81641 18.1836 5.47953 18.1836 9.99822C18.1836 14.5169 14.5205 18.18 10.0018 18.18Z"
              stroke="#DD4646"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13 7L7 13"
              stroke="#DD4646"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7 7L13 13"
              stroke="#DD4646"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div class="questionnaire__header-title">{{ data.description }}</div>
      </div>

      <div
        v-if="activeStepCode === 'docs'"
        class="questionnaire__header-control"
      >
        <div @click="downloadAllDocuments" class="questionnaire__header-btn">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5"
              stroke="#0086B2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.83301 8.33374L9.99967 12.5004L14.1663 8.33374"
              stroke="#0086B2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 12.5V2.5"
              stroke="#0086B2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <span
        class="questionnaire__reason-link"
        v-if="
          (response?.rejection_reason || rejectionReasonFromRoutes) &&
          props.data?.active && props.data.stage === 'approving' && !!props.data?.rejection_reason
        "
        @click="
          onOpenRejectReason(
            response?.rejection_reason ? 'rejection' : 'not_agreed'
          )
        "
        >Посмотреть причину</span
      >
    </div>
    <div
      :class="[
        'questionnaire__content',
        response?.status === 'resume_review'
          ? 'questionnaire__content_resume'
          : 'questionnaire__content_routes',
      ]"
    >
      <slot name="content"></slot>
    </div>
  </div>


  <ModalWindow
    v-if="showPopupCloseVacancy"
    @close="showPopupCloseVacancy = false"
    class="questionnaire__modal"
  >
    <RefusalModal
      @close="showPopupCloseVacancy = false"
      :textModal="textModal"
      @refusal="refusal"
    />
  </ModalWindow>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import responses from "@/api/responses";
import { usePDF } from "vue3-pdfmake";
import RefusalModal from "@/components/ModalWindow/ModalBodyes/RefusalModal";
import ErrorModal from "@/components/ModalWindow/ModalBodyes/ErrorModal";

const props = defineProps({
  data: Object,
  response: Object,
  actions: Array,
  steps: Object,
  active: Number,
  medicalBoard: Object,
  tickets: Object,
  activeStepCode: String,
  isDeadline: Boolean,
});
const pdf = usePDF();

const router = useRouter();
const route = useRoute();

const emit = defineEmits([
  "update",
  "fileError",
  "downloadAllDocuments",
  "openRejectReason",
]);

const textModal =
  "Благодарим за ваш отклик. Мы внимательно ознакомились с вашим резюме. К сожалению, на данном этапе мы не готовы предложить вам работу. Искренне желаем удачи в поисках работы!";

const showPopupCloseVacancy = ref(false);
const showPopupError = ref(false);
const actionId = ref(null);
const disabledBtns = ref(false);

const rejectionReasonFromRoutes = computed(() => {
  if (props.response?.route?.length) {
    return props.response?.route?.find((r) => !!r?.rejection_reason)
      ?.rejection_reason;
  }
  return "loh";
});

const onOpenRejectReason = (reason) => {
  emit("openRejectReason", reason, rejectionReasonFromRoutes.value);
};
const postStatus = async () => {
  disabledBtns.value = true;
  try {
    const form = ref({
      rejection_reason: textModal,
    });

    if (props.medicalBoard.file) {
      const formData = new FormData();
      formData.append("description", props.medicalBoard.description);
      formData.append("direction", props.medicalBoard.file);

      form.value = formData;
    } else {
      emit("fileError");
    }

    if (props.tickets.file) {
      const formData = new FormData();
      formData.append("description", props.tickets.description);
      formData.append("tickets", props.tickets.file);

      form.value = formData;
    } else {
      emit("fileError");
    }

    const response = await responses.postStatus(
      props.response.id,
      actionId.value,
      form.value
    );

    if (response.need_redirect) {
      if (route.meta.role === "HR") await router.push("/responses");
      if (route.meta.role === "MAIN_HR") await router.push("/responses");
      if (route.meta.role === "DIRECTION_LEADER")
        await router.push("/dl-tasks");
      if (route.meta.role === "SECURITY_SERVICE")
        await router.push("/sb-tasks");
    } else {
      emit("update");
    }

    disabledBtns.value = false;
  } catch (error) {
    disabledBtns.value = false;
    if (error.response.data?.error_code !== 400) {
      showPopupError.value = true;
    }
    console.log(error);
  }
};

const refusal = async () => {
  showPopupCloseVacancy.value = false;
  await postStatus();
  if (route.meta.role === "HR") await router.push("/responses");
  if (route.meta.role === "MAIN_HR") await router.push("/responses");
  if (route.meta.role === "DIRECTION_LEADER") await router.push("/dl-tasks");
  if (route.meta.role === "SECURITY_SERVICE") await router.push("/sb-tasks");
};

const clickButton = async (action) => {
  actionId.value = action.status_id;
  if (action.btn === "BTN_RED_BORDERED") {
    if (route.meta.role === "HR" || route.meta.role === "MAIN_HR") {
      showPopupCloseVacancy.value = true;
    } else {
      await postStatus();
      if (route.meta.role === "DIRECTION_LEADER")
        await router.push("/dl-tasks");
      if (route.meta.role === "SECURITY_SERVICE")
        await router.push("/sb-tasks");
    }
  } else {
    await postStatus();
  }
};

const activeStep = computed(() => {
  for (const item in props.steps) {
    if (props.steps[item].status_id === props.active) {
      return props.steps[item];
    }
  }
});

pdf.fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
};

const convertPdf = (type) => {
  if (activeStep.value.status_id !== 15) return;
  const field = activeStep.value.form_fields;

  const docDefinition = {
    content: [
      {
        text: `Должность, на которую претендуете: ${
          field[0]?.fields[0]?.value ? field[0]?.fields[0]?.value : "Не указано"
        } \n \n`,
        style: "header",
      },
      {
        style: "tableExample",
        table: {
          widths: [120, 120, 120, 120],
          body: [
            [
              {
                text: `Фамилия: \n ${
                  field[0]?.fields[1]?.value
                    ? field[0]?.fields[1]?.value
                    : "Не указано"
                }`,
                fontSize: 11,
              },
              {
                text: `Дата рождения: \n  ${
                  field[0]?.fields[4]?.value
                    ? field[0]?.fields[4]?.value
                    : "Не указано"
                } \n Возраст: ${
                  field[0]?.fields[5]?.value
                    ? field[0]?.fields[5]?.value
                    : "Не указано"
                }`,
                fontSize: 11,
              },
              {
                text: `Гражданство: \n   ${
                  field[0]?.fields[7]?.value
                    ? field[0]?.fields[7]?.value
                    : "Не указано"
                }`,
                fontSize: 11,
              },
              {
                text: `Семейное положение: \n   ${
                  field[0]?.fields[10]?.value
                    ? field[0]?.fields[10]?.value
                    : "Не указано"
                }`,
                fontSize: 11,
              },
            ],
            [
              {
                text: `Имя: \n ${
                  field[0]?.fields[2]?.value
                    ? field[0]?.fields[2]?.value
                    : "Не указано"
                }`,
                fontSize: 11,
              },
              {
                text: `Пол: ${
                  field[0]?.fields[6]?.value
                    ? field[0]?.fields[6]?.value
                    : "Не указано"
                }`,
                fontSize: 11,
              },
              {
                text: `Национальность: ${
                  field[0]?.fields[8]?.value
                    ? field[0]?.fields[8]?.value
                    : "Не указано"
                }  \n Родной язык  ${
                  field[0]?.fields[9]?.value
                    ? field[0]?.fields[9]?.value
                    : "Не указано"
                }`,
                fontSize: 11,
              },
              {
                text: `Дети: ${
                  field[0]?.fields[11]?.value
                    ? field[0]?.fields[11]?.value
                    : "Не указано"
                }  \n Количество:  ${
                  field[0]?.fields[12]?.value
                    ? field[0]?.fields[12]?.value
                    : "Не указано"
                } Возраст: ${
                  field[0]?.fields[13]?.value
                    ? field[0]?.fields[13]?.value
                    : "Не указано"
                }`,
                fontSize: 9,
              },
            ],
            [
              { text: `Адрес проживания`, fontSize: 11 },
              { text: `Телефоны`, fontSize: 11 },
              { text: `Командировки`, fontSize: 11 },
              { text: ``, fontSize: 11 },
            ],
            [
              {
                text: `Индекс:
                            \n ${
                              field[0]?.fields[14]?.value
                                ? field[0]?.fields[14]?.value
                                : ""
                            } \n
                            Обл., район, населенный пункт: ${
                              field[0]?.fields[15]?.value
                                ? field[0]?.fields[15]?.value
                                : ""
                            }`,
                fontSize: 11,
              },
              {
                text: `Служебный: ${
                  field[0].fields[18]?.value
                    ? field[0]?.fields[18]?.value
                    : "Не указано"
                }`,
                fontSize: 11,
              },
              {
                text: `Как часто вы могли бы ездить в командировки: ${
                  field[0]?.fields[21]?.value
                    ? field[0]?.fields[21]?.value
                    : "Не указано"
                }`,
                fontSize: 11,
              },
              { text: ``, fontSize: 11 },
            ],
            [
              {
                text: `Улица, дом, квартира: \n ${
                  field[0]?.fields[16]?.value
                    ? field[0]?.fields[16]?.value
                    : "Не указано"
                }`,
                fontSize: 11,
              },
              {
                text: `Домашний: \n ${
                  field[0]?.fields[19]?.value
                    ? field[0]?.fields[19]?.value
                    : "Не указано"
                }`,
                fontSize: 11,
              },
              { text: ``, fontSize: 11 },
              { text: ``, fontSize: 11 },
            ],
            [
              {
                text: `Ближайшая станция метро: \n ${
                  field[0]?.fields[17]?.value
                    ? field[0]?.fields[17]?.value
                    : "Не указано"
                }`,
                fontSize: 11,
              },
              {
                text: `Контактный: \n ${
                  field[0]?.fields[20]?.value
                    ? field[0]?.fields[20]?.value
                    : "Не указано"
                }`,
                fontSize: 11,
              },
              { text: ``, fontSize: 11 },
              { text: ``, fontSize: 11 },
            ],
          ],
        },
      },
      {
        text: "\n \n Высшее или средне специальное образование \n \n",
        style: "header",
      },
      {
        style: "tableExample",
        table: {
          widths: [60, 60, 230, 130],
          body: [
            [
              { text: `Год поступления`, fontSize: 9 },
              { text: `Год окончания`, fontSize: 9 },
              {
                text: `Полное название учебного заведения Факультет (отделение)`,
                fontSize: 10,
              },
              { text: `Специальность, квалификация`, fontSize: 10 },
            ],
            [
              {
                text: `${
                  field[1]?.fields[0]?.value
                    ? field[1]?.fields[0]?.value
                    : "Не указано"
                }`,
                fontSize: 9,
              },
              {
                text: `${
                  field[1]?.fields[1]?.value
                    ? field[1]?.fields[1]?.value
                    : "Не указано"
                }`,
                fontSize: 9,
              },
              {
                text: `${
                  field[1]?.fields[2]?.value
                    ? field[1]?.fields[2]?.value
                    : "Не указано"
                }`,
                fontSize: 10,
              },
              {
                text: `${
                  field[1]?.fields[3]?.value
                    ? field[1]?.fields[3]?.value
                    : "Не указано"
                }`,
                fontSize: 10,
              },
            ],
          ],
        },
      },
    ],
    defaultStyle: {
      fontSize: 13,
      bold: true,
    },
  };

  if (type === "download") {
    pdf.createPdf(docDefinition).download("doc");
  } else {
    pdf.createPdf(docDefinition).print();
  }
};

const downloadAllDocuments = () => {
  emit("downloadAllDocuments", props.activeStepCode);
};
</script>

<style scoped lang="scss">
.questionnaire {
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 2px 2px 8px 0px #0000000a;

  &__header {
    width: 100%;
    padding: 13px 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light-gray-1;
  }

  &__reason-link {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: $blue;
    cursor: pointer;
  }

  &__header-left {
    display: flex;
    align-items: center;
  }

  &__header-status {
    display: flex;
    align-items: center;
  }

  &__header-status-dot {
    width: 10px;
    height: 10px;
    border-radius: 30px;

    &_blue {
      background-color: $blue;
    }

    &_gray {
      background-color: #bbc4cd;
    }

    &_red {
      background-color: $red;
    }
  }

  &__header-status-icon {
    width: 20px;
  }

  &__header-title {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
  }

  &__header-status-name {
    margin-left: 8px;
    color: $blue;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;

    &_red {
      color: $red;
    }

    &_green {
      color: $green;
    }
  }

  &__header-control {
    display: flex;
    align-items: center;
  }

  &__header-btn {
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 16px;
    }

    &:hover {
      svg {
        path {
          stroke: #1E9AC6 !important;
        }
      }
    }

    &:active {
      svg {
        path {
          stroke: #006894 !important;
        }
      }
    }
  }

  &__content {
    height: 429px;
    max-height: 429px;
    overflow-y: auto;

    &_resume {
      height: auto;
      max-height: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__footer {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $light-gray-1;
  }

  &__footer-btn {
    &:not(:first-child) {
      margin-left: 12px;
    }
  }

  &__BTN_RED_BORDERED {
    width: calc(15.86%);
    background-color: rgba(221, 70, 70, 0.1) !important;
    color: $red !important;

    &:hover {
      background-color: rgba(221, 70, 70, 0.3) !important;
      color: $red !important;
    }

    &:active {
      background-color: rgba(221, 70, 70, 0.5) !important;
      color: $red !important;
    }
  }

  &__BTN_RED_BORDERED.questionnaire__footer-btn_full {
    width: auto;
    padding: 0 16px !important;
  }

  &__BTN_BLUE_BORDERED {
    width: 23.5%;
    background-color: rgba(13, 95, 203, 0.1) !important;
    color: $blue !important;

    &:hover {
      background-color: rgba(13, 95, 203, 0.3) !important;
      color: $blue !important;
    }

    &:active {
      background-color: rgba(13, 95, 203, 0.5) !important;
      color: $blue !important;
    }
  }

  &__BTN_BLUE_FILLED {
    width: 57.35%;

    &:active {
      background-color: #006894 !important;
    }
  }

  &__modal {
    &::v-deep(.modal__body) {
      width: 352px;
    }
  }

  &__error-modal {
    &::v-deep(.modal__body) {
      width: 304px;

      .modal-body__description {
        max-width: 300px;
      }
    }
  }
}
</style>
