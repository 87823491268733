<template>
  <div  class="steps" :key="stepsLength" :class="{ 'steps_stop-anim': stopAnimation }">
    <div>
      <div v-if="scroll > 0" @click="prev" class="steps__btn steps__btn_prev">
        <img src="@/assets/images/icons/right-arrow-btn.svg" alt="arrow" />
      </div>
      <div
        @click="next"
        v-if="scroll < stepsWrapper?.clientWidth - 880 && stepsLength > 4"
        class="steps__btn steps__btn_next"
      >
        <img src="@/assets/images/icons/right-arrow-btn.svg" alt="arrow" />
      </div>
    </div>

    <div
      class="steps__steps-texts"
      :class="{ 'steps__steps-texts_full': stepsLength < 4 }"
    >
      <div
        :class="[
          'steps__steps-texts-wrapper',
          { 'steps__steps-texts-wrapper_flex': stepsLength < 5 },
        ]"
        :style="{ marginLeft: `-${scroll}px` }"
      >
        <p
          v-for="(step, index) in steps"
          class="steps__steps-text"
          :key="index"
        >
          {{ step.title }}
        </p>
      </div>
    </div>
    <div class="steps__steps">
      <div
        ref="stepsWrapper"
        class="steps__steps-wrapper"
        :class="{
          'steps__steps-wrapper_full': stepsLength < 4,
          'steps__steps-wrapper_width-100': stepsLength < 5,
        }"
        :style="{ marginLeft: `-${scroll}px` }"
      >
        <div
          v-for="(step, index) in steps"
          :key="index"
          @click="open(index)"
          class="steps__step"
          :class="[
            { steps__step_active: index === currentStage },
            { 'steps__step_max-none': stepsLength < 5 },
          ]"
        >
          <div class="steps__step-content">
            <div
              class="steps__step-status"
              :class="{
                'steps__step-status_center': true,
              }"
            >
              <svg
                v-if="step.icons.state_icon === 'ACCEPTED'"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6" r="6" fill="#26A659" />
                <path
                  d="M8.65625 4.125L5.21875 7.5625L3.65625 6"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <svg
                v-if="step.icons.state_icon === 'REJECTED'"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_10239_140744)">
                  <path
                    d="M6.5 12C3.18629 12 0.499999 9.31371 0.499999 6C0.499998 2.68629 3.18629 8.1423e-07 6.5 5.24537e-07C9.81371 2.34843e-07 12.5 2.68629 12.5 6C12.5 9.31371 9.81371 12 6.5 12Z"
                    fill="#F04F4F"
                  />
                  <path
                    d="M8.74979 3.75L4.25 8.24979"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.25 3.75L8.74979 8.24979"
                    stroke="white"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_10239_140744">
                    <rect
                      width="12"
                      height="12"
                      fill="white"
                      transform="matrix(-1 8.74228e-08 8.74228e-08 1 12.5 0)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div class="steps__step-title">
              {{ step.responsible }}
            </div>
          </div>

          <svg
            width="12"
            height="46"
            viewBox="0 0 12 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0L12 23L0 46V0Z" fill="white" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, computed, watch, onBeforeMount} from "vue";
import {useRoute, useRouter} from "vue-router";
const route = useRoute()
const router = useRouter();

const props = defineProps({
  steps: Array,
});

const emit = defineEmits(["open"]);

const stepsWrapper = ref(null);
const scroll = ref(0);
const stepsLength = computed(() => props.steps?.length);
const stopAnimation = ref(false);
const currentStage = computed(() => Number(route.query?.stage) || 0);

const next = () => {
  scroll.value += 208;
  if (scroll.value > stepsWrapper.value.clientWidth - 832) {
    scroll.value = 0;
  }
};

const prev = () => {
  if (scroll.value > 0) scroll.value -= 208;
};

const open = (index) => {
  router.replace({ query: { stage: index} })
};


onBeforeMount(() => {
  if(route.query?.stage > 3) {
    scroll.value = (route.query?.stage -3) * 208
  }
});

watch(() => currentStage.value, () => {
  if(route.query?.stage > 3) {
    scroll.value = (route.query?.stage -3) * 208
  } else {
    scroll.value = 0
  }
})

onMounted(() => {
  if(!route.query?.stage) {
    let defaultStage = props?.steps.length - 1
    if( props?.steps.findIndex((step) => step?.status === "not_started") > 0 ) {
      defaultStage = props?.steps.findIndex((step) => step?.status === "not_started")
    }
    if(props?.steps.findIndex((step) => step?.active) > 0) {
      defaultStage = props?.steps.findIndex((step) => step?.active)
    }
    router.replace({ query: { stage: defaultStage}})
  }
});
</script>

<style scoped lang="scss">
.steps {
  position: relative;
  display: flex;
  flex-direction: column;

  &__btn {
    position: absolute;
    top: 27px;

    cursor: pointer;
    user-select: none;

    &_next {
      right: -43px;
    }

    &_prev {
      left: -43px;

      img {
        transform: rotate(-180deg);
      }
    }
  }

  &__steps-texts {
    display: flex;
    margin-bottom: 8px;
    color: $dark-blue;
    font-size: 12px;
    font-weight: 400;
    overflow-x: hidden;
  }

  &__steps-texts-wrapper {
    display: flex;
    transition: 0.3s;

    &_flex {
      width: 100%;

      & .steps__steps-text {
        display: flex;
        max-width: none !important;
        flex: 1 1 auto;
        justify-content: center;
      }
    }
  }

  &_stop-anim &__steps-texts-wrapper {
    transition: 0s;
  }

  &__steps-text {
    min-width: 196px;
    max-width: 196px;
    text-align: center;
    padding-left: 40px;

    &:not(:first-child) {
      margin-left: 13px;
    }
  }

  &__steps-texts_full &__steps-text {
    min-width: 260px;
    max-width: 260px;
  }

  &__steps {
    width: 100%;
    border-radius: 12px;
    display: flex;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.04);
    background-color: #fff;
    overflow-x: hidden;
  }

  &__steps-wrapper {
    display: flex;
    transition: 0.3s;

    &_width-100 {
      width: 100%;
    }
  }

  &_stop-anim &__steps-wrapper {
    transition: 0s;
  }

  &__step {
    position: relative;
    min-width: 211px;
    width: 100%; // check!
    height: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 10px 0px 8px 0px rgba(30, 57, 89, 0.06);
    cursor: pointer;
    z-index: 2;

    &:last-child {
      padding-right: 20px;
    }

    svg {
      position: absolute;
      right: -12px;
      top: 0;
    }

    &_active {
      background-color: $blue;
      color: #fff;
      z-index: 0;

      & > svg {
        path {
          fill: $blue;
        }
      }
    }

    &_max-none {
      max-width: none;
    }
  }

  &__steps-wrapper_full &__step {
    min-width: 277px;
    max-width: 277px;
  }

  &__steps-wrapper_full .steps__step_max-none {
    max-width: none;
  }

  &__step-content {
    position: relative;
    margin-left: 20px;
  }

  &__step-status {
    position: absolute;
    left: -20px;
    top: 2.5px;
  }

  &__step-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -19px;
    color: $dark-blue;
    font-size: 12px;
    font-weight: 400;
  }

  &__step-title {
    max-width: 133px;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__step-subtitle {
    max-width: 130px;
    color: $gray;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__step_active &__step-subtitle {
    color: #fff;
  }
}
</style>
