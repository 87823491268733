<template>
  <div class="response" v-if="response">
    <InternalHeader
      backBtn="Задачи"
      backBtnLink="/responses"
      title="Карточка задачи"
      class="response__header"
    />

    <div class="response__row">
      <div :key="updateIndex" class="response__wrapper container">
        <ResponseBaseInfoBlock
          v-if="response.base_info"
          :baseInfo="response.base_info"
        />
        <h3
          class="response__title"
          v-if="
            response?.route?.length &&
            response?.status !== 'resume_review' &&
            !(response.status === 'rejected' && response?.route?.length === 1)
          "
        >
          Маршрут согласования<span class="response__title-route" v-if="response?.base_info?.vacancy?.route">{{ response?.base_info?.vacancy?.route }}</span>
        </h3>
        <ResponseRouteBlock
          v-if="
            response?.route?.length &&
            response?.status !== 'resume_review' &&
            !(response.status === 'rejected' && response?.route?.length === 1)
          "
          :steps="response.route"
          class="response__steps"
        />
        <div class="response__stages" v-if="response.route">
          <StagesWrapper
            v-if="activeStageData"
            :key="updateIndex"
            :data="activeStageData"
            :isDeadline="response?.deadline"
            :activeStepCode="activeStepCode"
            :response="response"
            class="response__stages-block"
            @openRejectReason="onOpenRejectReason"
            @fileError="fileValidateError = true"
            @downloadAllDocuments="onDownloadAllDocuments"
          >
            <template #content>
              <div>
                <NewResume
                  v-if="activeStageData.stage === 'resume'"
                  :data="activeStageData"
                />
                <ResponseApprovingPersonBlock
                  v-if="activeStageData.stage === 'approving'"
                  :data="activeStageData"
                  :baseInfo="response?.base_info"
                />
                <DocsBlock
                  v-if="activeStageData.stage === 'documents'"
                  :data="activeStageData"
                />
                <MedicalBlock
                  v-if="activeStageData.stage === 'medical'"
                  :key="currentStage + updateStage"
                  @addInfo="addMedicalInfo"
                  :data="activeStageData"
                  :formErrors="medicalBlockErrors"
                  :showErrors="isMedicalBlockErrors"
                />
                <TicketsBlock
                  v-if="activeStageData.stage === 'tickets'"
                  :key="currentStage + updateStage"
                  @changeCheckbox="changeCheckbox"
                  @addInfo="addTicketsInfo"
                  @addTickets="addTickets"
                  @openChat="openChat(response.chat_room_id)"
                  @validateFields="onTicketsBlockValidate"
                  :showErrors="isTicketBlockValid"
                  :baseInfo="response?.base_info"
                  :data="activeStageData"
                  :role="role"
                />
              </div>
            </template>
          </StagesWrapper>
        </div>
      </div>
      <div class="response__col">
        <InfoMenu
          class="response__info-menu"
           :chatIsAvailable="response?.chats?.is_available"
          @openHistory="openHistory"
          @openChat="openChat(response.chat_room_id)"
          @toCandidateCard="$router.push(`/candidates/${response?.candidate?.id}`)"
        />
        <CandidateDuplicateElement
          v-if="response.candidate?.has_duplicate"
          @checkChanges="onCheckChanges"
        />
        <div>
          <ActionsBlock
              v-if="response?.route && response?.route[currentStage]?.actions?.length"
              class="response__actions-block response__actions-route"
              header-name="Действия по этапу"
              :actions="response?.route[currentStage]?.actions"
              @actionClick="onActionClick"
              @transferDecision="onTransferDecisionAction"
              :loading="loading"
          />
          <ActionsBlock
              v-if="response?.actions?.length"
              class="response__actions-block"
              :actions="response?.actions"
              @actionClick="onActionClick"
              @transferDecision="onTransferDecisionAction"
          />
        </div>

      </div>
    </div>
    <ModalWindow
      v-if="showApprovalHistoryModal"
      @close="showApprovalHistoryModal = false"
      class="response__approval-history-modal"
    >
      <ApprovalHistory
        :id="route.params.id"
        @close="showApprovalHistoryModal = false"
      />
    </ModalWindow>

    <ChatWindow
      v-if="showChat"
      :responseId="route.params.id"
      :setRoomId="response.chat_room_id"
      @close="showChat = false"
    />

    <ModalWindow
      v-if="showPopupError"
      @close="showPopupError = false"
      class="response__error-modal"
    >
      <ErrorModal
        @close="showPopupError = false"
        title="Ошибка"
        description="Проверьте подключение к Интернету."
      />
    </ModalWindow>
    <ModalWindow
      v-if="isTransferDecisionOpened"
      @close="isTransferDecisionOpened = false"
      class="response__transfer-decision-modal"
    >
      <TransferDecision
        @close="isTransferDecisionOpened = false"
        @submit="onTransferDecisionSubmit"
      />
    </ModalWindow>
    <ModalWindow
      class="response__modal-poups"
      :showModalWithOverflow="isModalEditComponentsOpened"
      v-show="isModalEditComponentsOpened"
      @close="isModalEditComponentsOpened = false"
    >
      <component
        v-if="responseDocumentsEdit"
        :is="modalEditComponentsMap[currentEditModal].component"
        :data="responseDocumentsEdit"
        :responseId="route.params.id"
        @close="isModalEditComponentsOpened = false"
        v-bind="modalEditComponentsMap[currentEditModal].binds"
        v-on="modalEditComponentsMap[currentEditModal].events"
      />
    </ModalWindow>
  </div>
  <AccessClosed v-if="response.blocked_by_contract" />
</template>

<script setup>
import {onActivated, onDeactivated, onMounted, ref, computed, watch} from "vue";
import { useRoute, useRouter } from "vue-router";

import responses from "@/api/responses";
import api from "@/api/api";
import InternalHeader from "@/components/InternalHeader";
import VacancyCard from "@/components/ResponseDetailCard/VacancyCard";
import ResponseBaseInfoBlock from "@/components/ResponseDetailCard/ResponseBaseInfoBlock";
import InfoMenu from "@/components/ResponseDetailCard/InfoMenu";
import ActionsBlock from "@/components/ResponseDetailCard/ActionsBlock";
import StepsBlock from "@/components/StepsBlock";
import ResponseRouteBlock from "@/components/ResponseDetailCard/ResponseRouteBlock";
import StagesWrapper from "@/components/ResponseDetailCard/Stages/StagesWrapper";
import NewResume from "@/components/ResponseDetailCard/NewResume";
import ResumeApprovingPerson from "@/components/ResponseDetailCard/ResumeApprovingPerson";
import ResponseApprovingPersonBlock from "@/components/ResponseDetailCard/ResponseApprovingPersonBlock";
import DocsBlock from "@/components/ResponseDetailCard/DocsBlock";
import DocsApprovingPerson from "@/components/ResponseDetailCard/DocsApprovingPerson";
import MedicalBlock from "@/components/ResponseDetailCard/MedicalBlock";
import MedicalApprovingPerson from "@/components/ResponseDetailCard/MedicalApprovingPerson";
import TicketsBlock from "@/components/ResponseDetailCard/TicketsBlock";
import ApprovalHistory from "@/components/ModalWindow/ModalBodyes/ApprovalHistory";
import ChatWindow from "@/components/ChatWindow";
import ErrorModal from "@/components/ModalWindow/ModalBodyes/ErrorModal";
import TransferDecision from "@/components/ModalWindow/ModalBodyes/TransferDecision";
import AccessClosed from "@/components/AccessClosed";
import CandidateDuplicateElement from "@/components/CandidateDuplicateElement";
import ResponseDocumentsEditModal from "@/components/ModalWindow/ModalBodyes/ResponseDocumentsEditModal";
import ResponseMedicalEditModal from "@/components/ModalWindow/ModalBodyes/ResponseMedicalEditModal";
import ResponseSelectRouteModal from "@/components/ModalWindow/ModalBodyes/ResponseSelectRouteModal";
import ResponseApplicantRejectionModal from "@/components/ModalWindow/ModalBodyes/ResponseApplicantRejectionModal";
import ResponseRefusalToApprove from "@/components/ModalWindow/ModalBodyes/ResponseRefusalToApprove";
import RefusalModal from "@/components/ModalWindow/ModalBodyes/RefusalModal";
import ResponseReasonsDeclineModal from "@/components/ModalWindow/ModalBodyes/ResponseReasonsDeclineModal";
import { debounce } from "@/services/helpers";
import { useStore } from "vuex";
const store = useStore();
const role = store.getters["auth/role"];
const route = useRoute();
const router = useRouter();
import eventBus from "@/services/eventBus";
const loaderStart = () => {
  eventBus.emit("loaderOn");
}
const loaderStop = () => {
  eventBus.emit("loaderOff");
}
const loading = ref(false)

const response = ref({});
const showChat = ref(false);
const chatId = ref(null);
const showPopupError = ref(false);
const showDublicateElement = ref(false);
const isResponseDocumentsEditOpened = ref(false);
const isResponseMedicalEditOpened = ref(false);
const isResponseSelectRouteOpened = ref(false);
const responseDocumentsEdit = ref();
const medicalBoard = ref({
  memo: "",
  file: null,
});
const tickets = ref({
  description: "",
  file: null,
});
const ticketBlockErrors = ref({});
const medicalBlockErrors = ref({
  files: "",
  memo: "",
});
const isTicketBlockValid = ref(false);
const isMedicalBlockErrors = ref(false);
const fileValidateError = ref(false);
const actionBody = ref();
const showApprovalHistoryModal = ref(false);
const currentStage = computed(() => Number(route.query?.stage) || 0);
const updateStage = ref(0)

const activeStepCode = ref(null);
const isTransferDecisionOpened = ref(false);
const isTransferDecisionLoading = ref(false);
const isModalEditComponentsOpened = ref(false);
const currentEditModal = ref("NEED_SET_MEDICAL_POPUP");
const modalEditComponentsMap = computed(() => {
  return {
    NEED_SET_DOCUMENTS_POPUP: {
      component: ResponseDocumentsEditModal,
      events: {
        success: onSuccessAttach,
        close: (isModalEditComponentsOpened.value = false),
      },
      binds: {},
    },
    NEED_SET_MEDICAL_POPUP: {
      component: ResponseMedicalEditModal,
      events: {
        success: onSuccessMedicalAttach,
        close: (isModalEditComponentsOpened.value = false),
      },
      binds: {},
    },
    SELECT_ROUTE_POPUP: {
      component: ResponseSelectRouteModal,
      events: {
        accept: onAcceptRouteProcess,
        close: (isModalEditComponentsOpened.value = false),
      },
      binds: {},
    },
    HR_REJECTION_POPUP: {
      component: ResponseApplicantRejectionModal,
      events: {
        success: onSuccessAttach,
        refusal: onRefusal,
        close: (isModalEditComponentsOpened.value = false),
      },
      binds: {},
    },
    ResponseReasonsDeclineModal: {
      component: ResponseReasonsDeclineModal,
      events: {
        success: onSuccessAttach,
        close: (isModalEditComponentsOpened.value = false),
      },
      binds: {
        title: responseDocumentsEdit.value?.title,
        description: responseDocumentsEdit.value?.description,
      },
    },
    DELAY_DECISION_POPUP: {
      component: TransferDecision,
      events: {
        close: (isModalEditComponentsOpened.value = false),
        submit: onTransferDecisionSubmit,
      },
      binds: {},
    },
    APPROVER_REJECTION_POPUP: {
      component: ResponseRefusalToApprove,
      events: {
        close: (isModalEditComponentsOpened.value = false),
        reject: onRejectedByApprover,
      },
      binds: {},
    },
  };
});
const openChat = (id) => {
  if (!navigator.onLine) {
    showPopupError.value = true;
    return;
  }
  chatId.value = id;
  showChat.value = true;
};

const openHistory = () => {
  if (!navigator.onLine) {
    showPopupError.value = true;
    return;
  }
  showApprovalHistoryModal.value = true;
};

const onCheckChanges = () => {
  router.push(`/candidates/${response.value?.candidate?.id}`);
};

const updateIndex = ref(1);

const onTicketsBlockValidate = (formErrors) => {
  ticketBlockErrors.value = formErrors;
};

const validateTicketsBlock = () => {
  return !Object.values(ticketBlockErrors.value).some((val) => !!val);
};

const onRefusal = (data) => {
  responses
    .handleAction(route.params.id, actionBody.value.action, data)
    .then(() => {
      setTimeout(() => {
        getResponse();
        isModalEditComponentsOpened.value = false;
      }, 50);
    });
};

const onRejectedByApprover = (data) => {
  responses
    .handleAction(route.params.id, actionBody.value.action, data)
    .then(() => {
      router.push("/responses");
      setTimeout(() => {
        isModalEditComponentsOpened.value = false;
      }, 50);
    });
};
const getResponse = async (stepIndex) => {
  try {
    response.value = await responses.getResponse(route.params.id);
    activeStepCode.value = response.value.route?.[currentStage.value]?.stage;
    updateIndex.value = stepIndex || updateIndex.value++;
    updateStage.value++
    loading.value = false;
  } catch (error) {
    console.log(error);
    loading.value = false;
  }
};

const debouncedGetResponse = debounce(() => getResponse(), 200);

const update = async () => {
  fileValidateError.value = false;
  await getResponse();

  medicalBoard.value = {
    memo: "",
    direction: null,
  };
};

const activeStageData = computed(() => {
  return response.value.route[currentStage.value];
});


watch(() => currentStage.value, () => {
  activeStepCode.value = response.value.route?.[currentStage.value]?.stage;
})

const medicalInfo = ref({});
const ticketsInfo = ref({});
const ticketsBody = ref({});
const addMedicalInfo = (body) => {
  medicalInfo.value = body;
  isMedicalBlockErrors.value = false;
};
const addTicketsInfo = (body) => {
  ticketsInfo.value = body;
};

const attachDocuments = (structuredDocuments, deletedDocuments, step) => {
  const stepsMap = {
    documents: 101,
    medical: 102,
  };
  const formData = new FormData();
  loaderStart();
  if (step === "documents") {
    structuredDocuments.forEach((section, sectionIndex) => {
      section.downloaded.forEach((doc, docIndex) => {
        if (!doc?.id) {
          formData.append(`files[${section.field_id}][]`, doc);
        }
      });
    });

    if (deletedDocuments?.length) {
      deletedDocuments.forEach((id) => {
        formData.append(`files_for_delete[]`, id);
      });
    }
  } else if (step === "medical") {
    structuredDocuments.forEach((doc, docIndex) => {
      if (!doc?.id) {
        formData.append(`files[]`, doc);
      }
    });

    if (deletedDocuments?.length) {
      deletedDocuments.forEach((id) => {
        formData.append(`files_for_delete[]`, id);
      });
    }
  } else if (step === "tickets") {

    structuredDocuments.forEach((doc, docIndex) => {
      if (!doc?.id) {
        formData.append(`files[]`, doc);
      }
    });

    if (deletedDocuments?.length) {
      deletedDocuments.forEach((id) => {
        formData.append(`files_for_delete[]`, id);
      });
    }
  }

  const stage = response.value?.route[currentStage.value]?.stage_number
  responses.setWithDocuments(route.params.id, step, formData, stage).then((res) => {
    if (res) {
      getResponse();
      loaderStop();
    }
  }).catch(()=>{
    loaderStop();
  });
};

const onOpenRejectReason = (reason, notAprovingText) => {
  currentEditModal.value = "ResponseReasonsDeclineModal";
  responseDocumentsEdit.value = {
    ...response.value,
    title: reason === "rejection" ? "Причина отказа" : "Причина несогласования",
    description:
      reason === "rejection"
        ? response.value?.rejection_reason
        : notAprovingText,
  };
  isModalEditComponentsOpened.value = true;
  setTimeout(() => {
    isModalEditComponentsOpened.value = true;
  }, 30);
};
const onSuccessAttach = (structuredDocuments, deletedDocuments) => {
  isResponseDocumentsEditOpened.value = false;
  isModalEditComponentsOpened.value = false;
  attachDocuments(structuredDocuments, deletedDocuments, "documents");
};

const onSuccessMedicalAttach = (structuredDocuments, deletedDocuments) => {
  isResponseMedicalEditOpened.value = false;
  isModalEditComponentsOpened.value = false;
  attachDocuments(structuredDocuments, deletedDocuments, "medical");
};

const onAcceptRouteProcess = (routeId) => {
  setTimeout(() => {
    isModalEditComponentsOpened.value = false;
  }, 40);

  const stage = response.value?.route[currentStage.value]?.stage_number
  responses.postResponsesAcceptRoute(route.params.id, routeId, stage).then(() => {
    isModalEditComponentsOpened.value = false;
    getResponse();
  });
};
const validateMedicalBlock = () => {
  if (!medicalInfo.value?.files || medicalInfo.value?.files.length === 0) {
    medicalBlockErrors.value.files = true;
  } else {
    medicalBlockErrors.value.files = false;
  }
  if (medicalInfo.value?.showMemo && !medicalInfo.value.memo) {
    medicalBlockErrors.value.memo = true;
  } else {
    medicalBlockErrors.value.memo = false;
  }
  return !Object.values(medicalBlockErrors.value).some((val) => !!val);
};

const addTickets = (body) => {
  ticketsBody.value = body;
};

const checkboxValue = ref(true);
updateStage.value++
const changeCheckbox = async (val) => {
  checkboxValue.value = val;
  const formData = new FormData();
  if (
    activeStageData.value.status === "waiting_brief" ||
    activeStageData.value.status === "eded" ||
    activeStageData.value?.tickets_needs
  ) {
    formData.append("available_tickets", checkboxValue.value);
  }
  const responseData = await responses.postResponsesChangeTickets(
    route.params.id,
    checkboxValue.value,
    response.value?.route[currentStage.value]?.stage_number
  );

  await getResponse();
};

const onActionClick = async (body) => {
  const formData = new FormData();
  if(body.btn === 'BLUE_FILLED'){
    loading.value = true
  }
  const stage = response.value?.route[currentStage.value]?.stage_number

  actionBody.value = body;
  responseDocumentsEdit.value = null;
  if (body.use === "NEED_SET_DOCUMENTS_POPUP") {
    currentEditModal.value = "NEED_SET_DOCUMENTS_POPUP";
    responses.getResponseDocuments(route.params.id, "documents", stage).then((res) => {
      if (res?.data) {
        responseDocumentsEdit.value = res?.data;
        setTimeout(() => {
          isModalEditComponentsOpened.value = true;
        }, 20);
      }
    });
    return;
  }

  if (body.use === "NEED_SET_MEDICAL_POPUP") {
    currentEditModal.value = "NEED_SET_MEDICAL_POPUP";
    responses.getResponseDocuments(route.params.id, "medical", stage).then((res) => {
      if (res?.data) {
        responseDocumentsEdit.value = res?.data;
        setTimeout(() => {
          isModalEditComponentsOpened.value = true;
        }, 30);
      }
    });
    return;
  }

  if (body.use === "HR_REJECTION_POPUP") {
    responseDocumentsEdit.value = null;
    currentEditModal.value = "HR_REJECTION_POPUP";
    responses.getResponsesRejectionReasons().then((res) => {
      if (res) {
        responseDocumentsEdit.value = res;
        setTimeout(() => {
          isModalEditComponentsOpened.value = true;
        }, 50);
      }
    });
    return;
  }

  if (body.use === "SELECT_ROUTE_POPUP") {
    currentEditModal.value = "SELECT_ROUTE_POPUP";

    responses
      .getResponsesVacanciesRoutes(response.value.base_info.vacancy.id)
      .then((res) => {
        if (res?.data) {
          responseDocumentsEdit.value = res.data;
          setTimeout(() => {
            isModalEditComponentsOpened.value = true;
          }, 30);
        }
      });
    return;
  }

  if (body.use === "APPROVER_REJECTION_POPUP") {
    currentEditModal.value = "APPROVER_REJECTION_POPUP";
    isModalEditComponentsOpened.value = true;
    responseDocumentsEdit.value = {
      title: "Причина несогласования",
      description: "Отдыхай",
      rejection_reason: "comments",
    };
    setTimeout(() => {
      isModalEditComponentsOpened.value = true;
    }, 200);
    return;
  }

  if (
    activeStepCode.value === "medical" &&
      body.id.includes("goToWaitingMedicalResults")
  ) {

    isMedicalBlockErrors.value = !validateMedicalBlock();

    if (isMedicalBlockErrors.value) {
      loading.value = false;
      return;
    }

    Object.entries(medicalInfo.value)?.forEach(([key, val]) => {
      if (key === "files") {
        val?.forEach((file) => {
          formData.append("direction[]", file);
        });
      } else if (key === "showMemo") {
        // continue;
      } else {
        formData.append(key, val);
      }
    });
  }

  if (
    activeStepCode.value === "tickets" &&
    // body.id.includes("confirmArrival") ||
    body.id.includes("goToArrivalWaiting") || body.id.includes("goToWaitingTickets")
  ) {
    const validatedTicketsBlock = validateTicketsBlock();
    if (!validatedTicketsBlock) {
      isTicketBlockValid.value = !validatedTicketsBlock;
      return;
    } else {
      formData.append("departure_city_id", ticketsInfo.value.departure_city_id);
      formData.append("departure_date", ticketsInfo.value.departure_date);
      if (ticketsInfo.value.passport?.length) {
        ticketsInfo.value.passport?.forEach((p) => {
          if (!p?.id) {
            formData.append("passport[]", p);
          }
        });
      }

      if (ticketsInfo.value.files?.length) {
        ticketsInfo.value.files?.forEach((file) => {
          formData.append("tickets[]", file);
        });
      }
      if (ticketsInfo.value?.filesForDelete?.length) {
        formData.append("files_for_delete[]", ticketsInfo.value.filesForDelete);
      }
    }
  }

  if (
    (activeStepCode.value === "tickets" && body.id.includes("confirmArrival")) ||
    body.id.includes("goToArrivalWaiting")  ||
    activeStageData.value?.employee?.is_me
  ) {
    const validatedTicketsBlock = validateTicketsBlock();

    formData.append("arrival_datetime", ticketsBody.value.arrival_datetime);

    ticketsBody.value.files?.forEach((file) => {
      formData.append("tickets[]", file);
    });
  }

  const postStatusResponse = await responses.handleAction(
    route.params.id,
    body.action,
    formData
  ).catch((err) => {
    loading.value = false;
  })

  if (
    role === "APPROVING_PERSON" &&
    (body?.id.includes("approve") ||
      body?.id.includes("rejectedByApprover") ||
      body?.id.includes("sendBriefForRevision") ||
      body?.id.includes("goToArrivalWaiting"))
  ) {
    await router.push("/responses");
    return;
  }

  if(body.id.includes('accept') || body.id.includes('confirm')) {
    if(body.id.includes("next_stage")) {
      await router.replace({ query: { stage: Number(route.query?.stage) + 1 } })
      await getResponse();
    } else {
      await getResponse();
    }
  } else {
    await getResponse();
  }

  if (
    (activeStageData.value.need_redirect &&
      !activeStageData.value.employee.is_me) ||
    postStatusResponse?.need_redirect
  ) {
    await router.push("/responses");
  } else {
  }
};

const onTransferDecisionAction = () => {
  isTransferDecisionOpened.value = true;
};

const onTransferDecisionSubmit = async (data) => {
  if (isTransferDecisionLoading.value) return;
  isTransferDecisionLoading.value = true;
  const body = {
    deadline: data?.date.toISOString().slice(0, 10),
  };
  const response = await responses
    .transferDecision(route.params.id, body)
    .then(() => getResponse())
    .finally(() => {
      isTransferDecisionOpened.value = false;
      isTransferDecisionLoading.value = false;
    });
};

const onDownloadAllDocuments = async (activeStepCode) => {
  if (activeStageData.value?.documents) {
    let link = document.createElement("a");
    link.setAttribute("download", "");
    link.setAttribute("data-href", "#");
    document.body.appendChild(link);
    activeStageData.value.documents?.forEach((document, index) => {
      setTimeout(() => {
        if (window.navigator.userAgent.indexOf("Firefox") > -1) {
          window.open(document.path);
        } else {
          link.href = document.path;
          link.click();
        }
      }, 300 * index);
    });
    document.body.removeChild(link);
  }
};
onActivated(() => {
  window.scrollTo(0, -10000);
  debouncedGetResponse();
});

onDeactivated(() => {
  response.value = {};
});

onMounted(() => {
  if (!response.value?.response) {
    debouncedGetResponse();
  }
});
</script>

<style scoped lang="scss">
.response {
  padding-bottom: 32px;

  &__header {
    margin-top: 12px;
  }

  &__row {
    margin-top: 24px;
    display: flex;
  }

  &__col {
    width: 256px;
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: auto;
    justify-content: space-between;
  }

  &__stretch-block {
    flex: 1 1 auto;
    width: 100%;
    min-height: 200px;
    height: 100%;
  }

  &__comments-block {
    margin-top: 106px;
  }

  &__actions-block {
    margin-top: auto;
  }

  &__actions-route {
    margin-bottom: 8px;
    margin-bottom: 24px;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-top: 32px;
    font-size: 24px;
    font-weight: 700;
  }

  &__title-route {
    margin-left: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: 0%;
    color: $gray;
  }

  &__steps {
    margin-top: calc(16px + 6px);
  }

  &__stages {
    margin-top: 16px;
  }

  &__stages-block {
    width: 100%;
  }

  &__approval-history-modal {
    &::v-deep(.modal__body) {
      width: 928px;
    }
  }

  &__error-modal {
    &::v-deep(.modal__body) {
      width: 304px;

      .modal-body__description {
        max-width: 300px;
      }
    }
  }

  &__transfer-decision-modal {
    &::v-deep(.modal__body) {
      width: 544px;
      border-radius: 16px;
    }
  }

  &__documents-edit-modal {
    &::v-deep(.modal__body) {
      width: 832px;
      border-radius: 16px;
      height: 457px;
    }
  }

  &__modal-poups {
    &::v-deep(.modal__body) {
      width: auto;
      height: auto;
      border-radius: 16px;
    }
  }

  &__info-menu {
    margin-bottom: 24px;
  }
}
</style>
