<template>
  <div class="reason-decline">
    <div class="reason-decline__close" @click="$emit('close')">
      <img src="@/assets/images/icons/x.svg" alt="close" />
    </div>
    <img
      class="reason-decline__icon"
      src="@/assets/images/icons/x-red.svg"
      alt="close"
    />
    <h3 class="reason-decline__title">{{ props?.title }}</h3>
    <p class="reason-decline__description">{{ props?.description }}</p>

    <VButton
      label="Понятно"
      class="reason-decline__btn"
      bg="#0086B2"
      color="#fff"
      bgHover="#BBC4CD"
      colorHover="#1E3959"
      @click="$emit('close')"
      clickEffect
    />
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
});

const route = useRoute();

const vacancyId = route.params.id;
</script>

<style scoped lang="scss">
.reason-decline {
  width: 352px;
  position: relative;
  padding: 40px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__icon {
    margin-bottom: 16px;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: center;
  }

  &__description {
    max-width: 304px;
    margin-top: 8px;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    text-align: center;
  }

  &__btn {
    width: 100%;
  }
}
</style>
