<template>
  <div class="response-documents">
    <div class="response-documents__header">
      <h2 class="response-documents__title">Выбор маршрута согласования</h2>
      <div class="response-documents__close" @click.prevent="$emit('close')">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 5L5 15"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5 5L15 15"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="response-documents__body">
      <p class="response-documents__body-description">
        Выберите маршрут согласования данного кандидата
      </p>

      <div class="response-documents__routes">
        <label class="response-documents__route" v-for="route in routes" :key="route"
            >
            <input class="response-documents__route-radio" type="radio" :value="route?.id"
              v-model="selectedRoute" /><span class="response-documents__route-name">{{ route?.name ? route?.name : 'Нет названия' }}</span>
          </label>
      </div>
    </div>
    <div class="response-documents__btns">
      <VButton
        label="Отменить"
        bg="#E4E7EE"
        bgHover="#BBC4CD"
        color="#1E3959"
        colorHover="#1E3959"
        class="response-documents__btn"
        @click="$emit('close')"
      />

      <VButton
        label="Подтвердить и перевести"
        bg="#0086B2"
        color="#fff"
        class="response-documents__btn"
        @click="startProccess"
      />
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, computed, onMounted } from "vue";
import responses from "@/api/responses";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import common from "@/api/common";
import { debounce } from "@/services/helpers";
const emit = defineEmits(["close", "resumeCreated", "success", "accept"]);
const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
  responseId: {
    type: [String, Number],
    required: false,
  },
});
const selectedRoute = ref()
const routes = ref([
  // 'Селигдар (956 км)',
  // 'Алекминск (212 км)',
  // 'ЛПУ'
])

const structuredDocuments = ref();

const rules = computed(() => {
  const rules = {
    sections: {},
  };

  if (structuredDocuments.value?.length < 1) {
    rules.sections = { required };
  }

  return rules;
});
const v$ = useVuelidate(rules, structuredDocuments);

const startProccess = async () => {
  // const validate = await v$.value.$validate();
  // if (!validate) {
  //   return;
  // }
  // // return//

  emit("accept", selectedRoute.value);
};

const selectRoute = (id) => {
  selectedRoute.value = id
}
onMounted(() => {
  if (props?.data) {
    routes.value = props?.data;
  }
});
</script>
<style lang="scss" scoped>
.response-documents {
  padding: 40px 24px 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 341px;
  width: 544px;

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  &__body-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: center;
  }

  &__routes {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }

  &__route {
    display: flex;
    padding: 10px 8px;
    cursor: pointer;
    &:not(:last-child){
      margin-bottom: 4px;
    }
  }

  &__route-radio {
    position: relative;
    flex-shrink: 0;
    appearance: none;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    border: 1px solid $light-gray-2;
    border-radius: 50%;

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 10px;
      height: 10px;
      border: 3px solid #fff;
      background-color: $blue;
      border-radius: 50%;
      transform: scale(0);
    }

    &:checked::before {
      transform: scale(1);
    }
  }

  &__radio-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;

  }

  &__button {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__btns {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    &:first-child {
      width: calc(50% - 8px);

      &:active {
        background-color: $gray !important;
      }
    }

    &:last-child {
      width: calc(50% - 8px);

      &:active {
        background-color: #006894 !important;
      }
    }
  }
}
</style>
