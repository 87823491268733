<template>
    <div class="resume">
        <div class="resume__main-info">
            <div class="resume__title">Основная информация</div>

            <div class="resume__main-info-list">
                <div class="resume__main-info-item">
                    <p class="resume__main-info-label">ФИО</p>
                    <div class="resume__main-info-text">{{ data.questionnaire?.name }}</div>
                </div>
                <div class="resume__main-info-item">
                    <p class="resume__main-info-label">Пол</p>
                    <div class="resume__main-info-text">{{ data.questionnaire?.gender }}</div>
                </div>
                <div class="resume__main-info-item">
                    <p class="resume__main-info-label">Город</p>
                    <div class="resume__main-info-text">{{ data.questionnaire?.locality?.name }}</div>
                </div>
                <div class="resume__main-info-item">
                    <p class="resume__main-info-label">Дата рождения</p>
                    <div class="resume__main-info-text">{{ dateFormaterToText(data.questionnaire?.birth_date) }}</div>
                </div>
                <div class="resume__main-info-item">
                    <p class="resume__main-info-label">Телефон</p>
                    <div class="resume__main-info-text">{{ data.questionnaire?.phone }}</div>
                </div>
                <div v-if="data.questionnaire?.additional_phone" class="resume__main-info-item">
                    <p class="resume__main-info-label">Дополнительный телефон</p>
                    <div class="resume__main-info-text">{{ data.questionnaire?.additional_phone }}</div>
                </div>
            </div>
        </div>

        <div class="resume__experience">
            <div class="resume__title">Опыт работы</div>

            <div v-if="data.questionnaire?.experiences?.length" class="resume__experience-list">
                <div v-for="(item, index) in data.questionnaire?.experiences" class="resume__experience-item" :key="index">
                    <div class="resume__experience-text">{{ item.company }}</div>
                    <div class="resume__experience-title">{{ item.specialty }}</div>
                    <div class="resume__experience-text-row">
                        <div class="resume__experience-text">{{ dateFormaterToText(item.start_date) }} -</div>
                        <div class="resume__experience-text resume__experience-text_end">
                            {{ item.end_date ? dateFormaterToText(item.end_date) : 'По настоящее время' }}
                        </div>
                    </div>
                    <p v-if="item.functions" class="resume__experience-description">{{item.functions}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
defineProps({
    data: Array
})

const dateFormaterToText = (stringDate) => {
    const monthNames = ["января", "февраля", "марта", "апреля", "мая", "июня",
    "июля", "августа", "сентября", "октября", "ноября", "декабря"
    ];
    const date = new Date(stringDate)
    return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`
}
</script>


<style scoped lang="scss">
.resume {
    padding: 24px;

    &__title {
        font-size: 16px;
        font-weight: 600;
    }

    &__main-info-list {
        margin-top: 16px;
        padding-bottom: 24px;
        display: grid;
        grid-template-columns: 232px 232px 232px;
        grid-column-gap: 44px;
        grid-row-gap: 24px;
        border-bottom: 1px solid $light-gray-1;
    }

    &__main-info-label {
        color: $light-gray-2;
        font-size: 12px;
        font-weight: 400;
    }

    &__main-info-text {
        margin-top: 4px;
        font-size: 14px;
        font-weight: 400;
    }

    &__experience {
        margin-top: 24px;
    }

    &__experience-list {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
    }

    &__experience-item {
        &:not(:last-child) {
            padding-bottom: 16px;
            border-bottom: 1px solid $light-gray-1;
        }

        &:not(:first-child) {
            padding-top: 16px;
        }
    }

    &__experience-text {
        color: $gray;
        font-size: 12px;
        font-weight: 400;

        &_end {
            margin-left: 2px;
        }
    }

    &__experience-text-row {
        display: flex;
    }

    &__experience-title {
        margin-top: 4px;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 500;
    }

    &__experience-description {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
    }
}
</style>
