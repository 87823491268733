<template>
  <div class="vacancy-card">
    <div class="vacancy-card__header">
      <div class="vacancy-card__tags">
        <div class="vacancy-card__tag">
          Дата отклика: {{ dateFormaterToText(baseInfo?.created_at) }}
        </div>
      </div>
      <h2 class="vacancy-card__header-title">{{ baseInfo.vacancy?.title }}</h2>
    </div>

    <div class="vacancy-card__footer">
      <div
        class="vacancy-card__footer-item vacancy-card__footer-item_full-name"
      >
        <div class="vacancy-card__footer-item-label">ФИО</div>
        <div class="vacancy-card__footer-item-value">
          {{ baseInfo.questionnaire?.name }}
        </div>
      </div>
      <div class="vacancy-card__footer-item vacancy-card__footer-item_city">
        <div class="vacancy-card__footer-item-label">Возраст</div>
        <div class="vacancy-card__footer-item-value">
          {{ baseInfo.questionnaire?.age }}
        </div>
      </div>
      <div
        class="vacancy-card__footer-item vacancy-card__footer-item_experience"
      >
        <div class="vacancy-card__footer-item-label">Опыт работы</div>
        <div class="vacancy-card__footer-item-value">
          {{ baseInfo.questionnaire?.total_experience }}
        </div>
      </div>
      <div class="vacancy-card__footer-item vacancy-card__footer-item_tel">
        <div class="vacancy-card__footer-item-label">Телефон</div>
        <div class="vacancy-card__footer-item-value">
          {{ baseInfo.questionnaire?.phone }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  baseInfo: {
    type: Object,
    required: true,
  },
});

const dateFormaterToText = (stringDate) => {
  const monthNames = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];
  const date = new Date(stringDate);
  return `${date.getDate()} ${
    monthNames[date.getMonth()]
  } ${date.getFullYear()}`;
};
</script>

<style scoped lang="scss">
.vacancy-card {
  width: 100%;
  padding: 24px 0;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 2px 2px 8px 0px #0000000a;

  &__header {
    padding: 0 24px 24px 24px;
  }

  &__tags {
    display: flex;
  }

  &__tag {
    color: $gray;
    font-size: 14px;
    font-weight: 400;
  }

  &__tag-dot {
    width: 8px;
    height: 8px;
    margin-right: 4px;
    border-radius: 100%;

    &_blue {
      background-color: $blue;
    }

    &_red {
      background-color: $red;
    }
  }

  &__header-title {
    margin-top: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  &__date {
    margin-top: 12px;
    font-size: 12px;
    font-weight: 400;
    color: $gray;
  }

  &__footer {
    padding: 0 24px;
    display: flex;
    align-items: center;
  }

  &__footer-item {
    height: 36px;

    &_full-name {
      width: 220px;
    }

    &_city {
      width: 57px;
    }

    &_experience {
      width: 142px;
    }

    &_tel {
      width: 149px;
    }

    &:not(:first-child) {
      margin-left: 73.6px;
    }
  }

  &__footer-item-label {
    color: $gray;
    font-size: 12px;
    font-weight: 400;
  }

  &__footer-item-value {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
